import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import OverviewPage from "../views/OverviewPage.vue";
import LivePage from "../views/LivePage.vue";
import SensorsPage from "../views/SensorsPage.vue";
import IncidentsPage from "../views/IncidentsPage.vue";
import EventsPage from "../views/EventsPage.vue";
import BurstsPage from "../views/BurstsPage.vue";
import TasksPage from "../views/TasksPage.vue";
import SettingsPage from "../views/SettingsPage.vue";
import GraphsPage from "../views/GraphsPage.vue";
import PlotsPage from "../views/PlotsPage.vue";
import AnimationPage from "../views/AnimationPage.vue";
import LoginPage from "../views/LoginPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Overview",
    component: OverviewPage,
  },
  {
    path: "/live",
    name: "Live",
    component: LivePage,
  },
  {
    path: "/sensors",
    name: "Sensors",
    component: SensorsPage,
  },
  {
    path: "/graphs",
    name: "Graphs",
    component: GraphsPage,
  },
  {
    path: "/incidents",
    name: "Incidents",
    component: IncidentsPage,
  },
  {
    path: "/events",
    name: "Events",
    component: EventsPage,
  },
  {
    path: "/bursts",
    name: "Bursts",
    component: BurstsPage,
    props: (route) => route.query,
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: TasksPage,
  },
  {
    path: "/plots",
    name: "Plots",
    component: PlotsPage,
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsPage,
  },
  {
    path: "/animation",
    name: "Animation",
    component: AnimationPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export { router };
