import { Temporal } from "@js-temporal/polyfill";
import { Page, PageSettings } from "@/types/Page";
import { ref, Ref } from "vue";
import { fetchGrafanaPreferences, putUpdateGrafanaPreferences } from "./api";
import { Burst } from "@/interfaces/Burst";

const CURRENT_SETTINGS_VERSION = "4";
const timezone = ref("") as Ref<Temporal.TimeZoneLike>;
const language = ref("");
const theme = ref("");
const authToken = ref(null as null | string);
const selectedPages = ref(null as null | PageSettings[]);
const editNavBar = ref(false);
const selectedBursts = ref([] as Burst[]);
const pageSettingsVersion = ref(null as null | string);
const maxBurstsSelectable = ref(-1);

const getAuthToken = () => {
  if (authToken.value === null) {
    authToken.value = localStorage.getItem("authToken");
  }
  return authToken.value;
};

const setAuthToken = (newValue: string) => {
  authToken.value = newValue;
  localStorage.setItem("authToken", newValue);
};

const clearAuthToken = () => {
  localStorage.removeItem("authToken");
  authToken.value = null;
};

const maxBurstsSelected = () =>
  getMaxBurstsSelectable().value <= getSelectedBursts().value.length;

const getMaxBurstsSelectable = () => {
  if (maxBurstsSelectable.value === -1) {
    maxBurstsSelectable.value = Number(
      localStorage.getItem("maxBurstsSelectable") || 100
    );
  }
  return maxBurstsSelectable;
};

const setMaxBurstsSelectable = (newValue: number) => {
  maxBurstsSelectable.value = newValue;
  localStorage.setItem("maxBurstsSelectable", newValue.toString());
};

const getPageSettingsVersion = () => {
  if (pageSettingsVersion.value === null) {
    pageSettingsVersion.value = localStorage.getItem("pageSettingsVersion");
  }
  return pageSettingsVersion;
};

const setPageSettingsVersion = (newValue: string) => {
  pageSettingsVersion.value = newValue;
  localStorage.setItem("pageSettingsVersion", newValue);
};

const getSelectedBursts = () => {
  let newSelectedBursts: Burst[] = [];
  if (selectedBursts.value.length < 1) {
    newSelectedBursts = JSON.parse(
      localStorage.getItem("selectedBursts") || "[]"
    );
    if (newSelectedBursts.length > 0) {
      selectedBursts.value = newSelectedBursts;
    }
  }
  return selectedBursts;
};

const addBurst = (burst: Burst) => {
  const mb = getMaxBurstsSelectable();
  if (!isBurstSelected(burst.BurstID) && mb.value) {
    if (getSelectedBursts().value.length >= mb.value) {
      throw new Error(`Bursts selection limit (${mb.value}) exceeded`);
    }
    selectedBursts.value = selectedBursts.value.concat(burst);
    localStorage.setItem(
      "selectedBursts",
      JSON.stringify(selectedBursts.value)
    );
  }
};

const removeBurst = (burstToRemove: Burst) => {
  selectedBursts.value = selectedBursts.value.filter((burst) => {
    if (burst.BurstID !== burstToRemove.BurstID) return burst;
  });
  localStorage.setItem("selectedBursts", JSON.stringify(selectedBursts.value));
};

const toggleBurst = (burst: Burst) => {
  isBurstSelected(burst.BurstID) ? removeBurst(burst) : addBurst(burst);
};

const isBurstSelected = (burstID: number) =>
  selectedBursts.value.reduce(
    (collector, { BurstID }) => (BurstID === burstID ? true : collector),
    false
  );

const getEditNavBar = () => editNavBar;

const setEditNavBar = (newValue: boolean) => {
  editNavBar.value = newValue;
};

const updatePages = (newPages: PageSettings[]) => {
  selectedPages.value = newPages;
  localStorage.setItem("selectedPages", JSON.stringify(selectedPages.value));
};

const updateSelectedPages = (page: Page, enabled: boolean): void => {
  if (selectedPages.value === null) {
    selectedPages.value = getSelectedPages().value;
  }
  if (selectedPages.value !== null) {
    selectedPages.value.filter((p) => {
      if (p.name === page) {
        p.enabled = enabled;
      }
    });
  }
  localStorage.setItem("selectedPages", JSON.stringify(selectedPages.value));
};

const getSelectedPages = () => {
  if (
    selectedPages.value === null ||
    getPageSettingsVersion().value !== CURRENT_SETTINGS_VERSION
  ) {
    const newSelectedPages = localStorage.getItem("selectedPages");
    if (
      newSelectedPages === null ||
      getPageSettingsVersion().value !== CURRENT_SETTINGS_VERSION
    ) {
      setPageSettingsVersion(CURRENT_SETTINGS_VERSION);
      selectedPages.value = [
        {
          enabled: true,
          name: "Overview",
          order: 0,
          link: "/",
        },
        {
          enabled: true,
          name: "Live",
          order: 1,
          link: "/live",
        },
        {
          enabled: false,
          name: "Sensors",
          order: 2,
          link: "/sensors",
        },
        {
          enabled: true,
          name: "Graphs",
          order: 3,
          link: "/graphs",
        },
        {
          enabled: true,
          name: "Incidents",
          order: 4,
          link: "/incidents",
        },
        {
          enabled: true,
          name: "Events",
          order: 5,
          link: "/events",
        },
        {
          enabled: true,
          name: "Bursts",
          order: 6,
          link: "/bursts",
        },
        {
          enabled: true,
          name: "Plots",
          order: 6,
          link: "/plots",
        },
        {
          enabled: true,
          name: "Analysis Tasks",
          order: 7,
          link: "/tasks",
        },
        {
          enabled: false,
          name: "Animation",
          order: 8,
          link: "/animation",
        },
      ] as PageSettings[];
    } else {
      selectedPages.value = JSON.parse(newSelectedPages) as PageSettings[];
    }
  }
  updatePages(selectedPages.value);
  return selectedPages;
};

const setLanguage = (newLanguage: string): void => {
  language.value = newLanguage;
  localStorage.setItem("language", language.value);
};

const getLanguage = () => {
  if (language.value === "") {
    language.value = localStorage.getItem("language") || navigator.language;
  }
  return language;
};

const setTheme = async (newTheme: string): Promise<void> => {
  theme.value = newTheme;
  localStorage.setItem("theme", theme.value);
  await updateGrafanaPreferences("theme", newTheme);
};

const updateGrafanaPreferences = async (
  type: "theme" | "timezone",
  newPreference: string
): Promise<void> => {
  const currentGrafanaPreferences = await fetchGrafanaPreferences();
  const newGrafanaPreferences = {
    ...currentGrafanaPreferences,
    [type]: newPreference,
  };
  await putUpdateGrafanaPreferences(newGrafanaPreferences);
};

const getTheme = () => {
  if (theme.value === "") {
    theme.value = localStorage.getItem("theme") || "light";
    updateGrafanaPreferences("theme", theme.value);
  }
  return theme;
};

const setTimeZone = async (newTimezone: string): Promise<void> => {
  timezone.value = newTimezone;
  localStorage.setItem("timezone", timezone.value);
  updateGrafanaPreferences("timezone", newTimezone);
};

const getUserTimeZone = () => {
  if (timezone.value === "") {
    timezone.value =
      localStorage.getItem("timezone") ||
      Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return timezone;
};

const getBrowserTimezone = (): Temporal.TimeZoneLike =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const getDateFormat = () =>
  getLanguage().value.toLowerCase() === "en-us"
    ? "MM/dd/yyyy HH:mm"
    : "dd/MM/yyyy HH:mm";

export {
  setTimeZone,
  getUserTimeZone,
  getBrowserTimezone,
  setLanguage,
  getLanguage,
  getDateFormat,
  setTheme,
  getTheme,
  updateSelectedPages,
  getSelectedPages,
  getEditNavBar,
  setEditNavBar,
  updatePages,
  isBurstSelected,
  toggleBurst,
  getSelectedBursts,
  addBurst,
  removeBurst,
  getMaxBurstsSelectable,
  setMaxBurstsSelectable,
  getAuthToken,
  setAuthToken,
  clearAuthToken,
  maxBurstsSelected,
};
